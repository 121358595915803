import isBrowserSupported from './browserSupportsSinglePeerConnection';

// SPC is only available if defined by sessionInfo or forced by flag
// and browser supports it.
export default (sessionInfo, overrideSinglePeerConnection) => {
  const { p2pEnabled, singlePeerConnection } = sessionInfo || {};
  if (p2pEnabled) {
    // SPC is not available for P2P sessions.
    return false;
  }

  // We override sessionInfo when internal flag is not true.
  const forceDisabled = overrideSinglePeerConnection !== true;

  // We use singlePeerConnection flag from GSI by default.
  const isSinglePeerConnectionEnabled = !!singlePeerConnection && !forceDisabled;

  return isSinglePeerConnectionEnabled && isBrowserSupported();
};
